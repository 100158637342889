<template>
  <div class="fixed-top w-50 mx-auto" style="margin-top: 85px">
    <b-alert
      v-for="(alert, index) in alerts"
      :key="index"
      :show="dismissCountDown"
      :variant="alert.type"
      dismissible
      fade
      @dismissed="dismissAlert"
      @dismiss-count-down="countDownChanged"

    >
      <span v-html="alert.text"></span>
    </b-alert>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'alert',
    data() {
      return {
        dismissSecs: 5,
        dismissCountDown: 0
      };
    },
    watch: {
      alerts(newCount, oldCount) {
        this.showAlert();
      }
    },
    computed: {
      ...mapGetters({
        alerts: "alerts/getAlerts"
      }),
    },
    methods: {
      dismissAlert() {
        this.$store.dispatch('alerts/emptyAlert')
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
    },
    mounted() {
      this.dismissAlert();
      this.showAlert();
    },
  };
</script>
