<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img
        v-if="currentUser && !currentUser.anonymous"
        src="/img/avatars/1.jpg"
        class="img-avatar"
        alt="admin@bootstrapmaster.com"/>
      <small v-if="currentUser && !currentUser.anonymous">
        {{ currentUser.username }}
      </small>
    </template>
    <template slot="dropdown">
      <div>
        <ol>
          <router-link :to="{name : 'changePassword'}">{{$t('message.change_password')}}</router-link>
        </ol>
      </div>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue';
  import Auth from "../components/auth/Auth";

  export default {
    name: 'DefaultHeaderDropdown',
    components: {
      AppHeaderDropdown,
    },
    data() {
      return {
        currentUser: null,
      };
    },
    mounted () {
      Auth.getCurrentUser().then((response) => (this.currentUser = response));
    }
  };
</script>

