<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" mobile :defaultOpen=false />
      <a href="https://www.lenovo.com/it/it/data-center/Lenovo-ThinkAgile/p/thinkagile"  target="_blank" class="navbar-brand">
        <div class="d-none d-md-flex ml-4 loghi-nav">
          <img src="/img/lenovo.svg" class="navbar-brand-full"  alt="">
          <img src="/img/logo-intel-rwd.png" class="navbar-brand-full" alt="">
        </div>
      </a>
      <div class="d-flex justify-content-center">
        <div>
          <router-link class="" :to="{name : 'categories'}">
            <img src="/img/logo_vps_grande.svg" class="navbar-brand-full ml-md-5" width="100" height="28" alt="">
          </router-link>
        </div>
      </div>
      <!-- no MOBILE -->
      <b-navbar-nav class="d-md-down-none">
        <div class="wrapper-nav-li">
          <b-nav-item>
            <a href="#" class="nav-link mr-3" @click="externalLink(links.landing)">Home</a>
          </b-nav-item>

        <b-nav-item class="" :to="{name : 'categories'}" v-if="currentRoute !== 'categories'" style="min-width: 130px !important;">
          {{$t('message.vps_text')}}
        </b-nav-item>
        <b-nav-item
          style="white-space:nowrap"
          class="px-3"
          exact
          :to="{name : 'login'}"
          v-if="currentUser && !currentUser.registered && !currentUser.customer && currentRoute !== 'login'">
           {{$t('message.login_or_register')}}
        </b-nav-item>

        <b-nav-item class="d-md-down-none pr-3 ml-3" v-if="!currentUser.customer && !currentUser.registered ">
          <div class="mr-3">
            <DefaultHeaderDropdownAccountAnonymous />
          </div>
        </b-nav-item>
        <b-nav-item
          class="d-md-down-none pr-4"
          v-if="currentUser && !currentUser.anonymous"
        >
          <DefaultHeaderDropdownAccnt
            v-if="currentUser && (currentUser.registered || currentUser.customer )"></DefaultHeaderDropdownAccnt>
        </b-nav-item>
          <b-nav-item v-if="isCartShow">
            <div class="ml-2 mr-4">
              <DefaultHeaderDropdownCart />
            </div>
          </b-nav-item>
        <b-nav-item>
          <language-selector/>
        </b-nav-item>
        </div>
      </b-navbar-nav>
      <!-- solo MOBILE -->
      <b-navbar-nav class="d-lg-none">
        <b-nav-item>
          <DefaultHeaderDropdownCart v-if="isCartShow"/>
        </b-nav-item>
        <b-nav-item>
          <language-selector/>
        </b-nav-item>
      </b-navbar-nav>
    </AppHeader>

    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <div class="hidden-m-up mt-1 p-3">
          <span v-if="currentRoute !== 'categories'">
             <div class="sidebarMenuLinks">
              <router-link :to="{name : 'categories'}" class="text-white"  v-on:click="">
                <i class="fa fa-list"></i>&nbsp; {{$t('message.vps_text')}}
              </router-link><br/>
             </div>
          </span>
          <span v-if="currentUser && !currentUser.registered && !currentUser.customer && currentRoute !== 'login'">
            <div class="sidebarMenuLinks">
             <router-link  :to="{name : 'login'}" class="text-white" >
              <i class="fa fa-sign-in"></i>&nbsp; {{$t('message.login_or_register')}}
             </router-link><br/>
            </div>
          </span>
          <span v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1)">
            <div class="sidebarMenuLinks">
              <i class="fa fa-lock"></i>&nbsp; <router-link :to="{name : 'changePassword'}" class="text-white" >{{$t('message.change_password')}}</router-link><br/>
            </div>
          </span>
          <span v-if="currentUser && currentUser.registered === 1">
            <div class="sidebarMenuLinks">
              <i class="fa fa-user"></i>&nbsp; <router-link :to="{name : 'register'}" class="text-white">{{$t('message.complete_registration_link')}}</router-link><br/>
            </div>
          </span>
          <span v-if="currentUser && currentUser.customer === 1">
            <div class="sidebarMenuLinks">
              <i class="fa fa-user"></i>&nbsp;<router-link :to="{name : 'profile'}" class="text-white" >{{$t('message.edit_profile')}}</router-link><br/>
            </div>
          </span>
          <span v-if="currentUser && currentUser.customer === 1">
            <div class="sidebarMenuLinks">
              <i class="fa fa-cogs"/>&nbsp; <a href="#"  class="text-white" @click="externalLink(links.control_panel)">{{$t('message.control_panel')}}</a><br/>
            </div>
          </span>
          <span v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1)">
            <div class="sidebarMenuLinks">
              <i class="fa fa-life-saver "></i> &nbsp;<router-link  :to="{name : 'support'}" class="text-white">{{$t('message.customer-care')}}</router-link><br/>
            </div>
          </span>
          <span v-else>
             <div class="sidebarMenuLinks">
              <i class="fa fa-life-saver "></i>&nbsp;<router-link :to="{name : 'anonymousSupport'}" class="text-white fontBigger">{{$t('message.customer-care')}}</router-link><br/>&nbsp;
            </div>
          </span>
          <span v-if="currentUser && (currentUser.customer === 1 || currentUser.registered === 1 )">
            <div class="sidebarMenuLinks">
              <i class="fa fa-star"></i>&nbsp;<router-link  :to="{name : 'feedback'}" class="text-white">{{$t('message.leave_feedback')}}</router-link><br/>
            </div>
          </span>
          <span v-else>
            <div class="sidebarMenuLinks">
              <i class="fa fa-star"></i>&nbsp;<router-link :to="{name : 'anonymousFeedback'}" class="text-white">{{$t('message.leave_feedback')}}</router-link><br/>
            </div>
          </span>
          <span v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1)">
            <div class="sidebarMenuLinks">
              <i class="fa fa-sign-out"></i>&nbsp; <a href="#"  class="text-white" v-on:click.prevent="doLogout">{{$t('message.logout')}}</a>
            </div>
          </span>
        </div>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>

      <main class="main" style="overflow: hidden">
        <!--<Breadcrumb :list="list"/> -->

          <div class="col-sm-12" style="background: transparent !important;">
            <div class="alert alert-warning" v-if="currentUser && currentUser.customer === 0 && currentUser.anonymous === 0 && currentUser.registered === 0 ">
              {{$t('message.account_not_confirmed')}}
            </div>
            <alert></alert>
          </div>

        <router-view :key="$route.fullPath"></router-view>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside/>
      </AppAside>
    </div>
    <footer class="app-footer">
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="startup-logo">
            <img src="/img/logo_footer_white.svg" class="img-fluid" alt="VPS Green" />
            <p>
              <a href="http://startup.registroimprese.it/isin/dettaglioStartup?1&id=KXU3bCJQBfS92AJOXqJVKg%2BWN%2BroDSUmx60lVQfhow%3D%3D" title="" target="_blank">
                <img src="/img/si.png" class="img-fluid mt-3" width="121" height="77"/>
              </a>
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <p>
                {{$t('footer_links.footer_company_row_0')}}<br/>
                {{$t('footer_links.footer_company_row_1')}}<br/>
                {{$t('footer_links.footer_company_row_2')}}<br/>
                {{$t('footer_links.footer_company_row_2_1')}}<br/>
                {{$t('footer_links.footer_company_row_3')}}<br/>
                {{$t('footer_links.footer_company_row_4')}}<br/>
                {{$t('footer_links.footer_company_row_4_1')}}<br/>
                {{$t('footer_links.footer_company_row_5')}}<br/>
                © 2017 - {{new Date().getFullYear()}} Lympha Lab S.r.l<br/>
              </p>
              <div class="paypal-logo">
                <img src="/img/paypal.jpg" alt="Paypal" class="paypal-logo" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <router-link class="footerLinks" :to="{name : 'categories'}">{{$t('message.vps_text')}}</router-link><br/>
              <div v-if="currentUser && currentUser.customer === 1">
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'changePassword'}">{{$t('message.change_password')}}</router-link><br/>
                </div>
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'profile'}">{{$t('message.edit_profile')}}</router-link><br/>
                </div>
                <div class="hideMobile">
                  <a href="#" class="footerLinks" @click="externalLink(links.control_panel)">{{$t('message.control_panel')}}</a><br/>
                </div>
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'feedback'}">{{$t('message.leave_feedback')}}</router-link><br/>
                </div>
                <div class="hideMobile">
                  <a href="#" class="footerLinks" v-on:click.prevent="doLogout">{{$t('message.logout')}}</a><br/>
                </div>
              </div>
              <div v-else >
                <router-link class="footerLinks" :to="{name : 'login'}">{{$t('message.login_or_register')}}</router-link><br/>
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'anonymousFeedback'}">{{$t('message.leave_feedback')}}</router-link><br/>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <div v-if="currentUser && currentUser.customer === 1">
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'support'}">{{$t('message.customer-care')}}</router-link><br/>
                </div>
                <a href="#" class="footerLinks" @click="externalLink(links.faq)">{{$t('message.faq')}}</a><br/>
                <!--<router-link class="footerLinks" :to="{name : 'videoGuides'}">{{$t('message.video_guides')}}</router-link><br/> -->
                <a href="#" class="footerLinks" @click="externalLink(links.cookie_policy_link)">{{$t('message.cookie_policy')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.tos_link)">{{$t('message.tos')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.gdpr_link)">{{$t('message.gdpr')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.sla_link)">{{$t('message.sla')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.use_policy_link)">{{$t('message.use_policy')}}</a><br/>
              </div>
              <div v-else >
                <div class="hideMobile">
                  <router-link class="footerLinks" :to="{name : 'anonymousSupport'}">{{$t('message.customer-care')}}</router-link><br/>
                </div>
                <a href="#" class="footerLinks" @click="externalLink(links.faq)">{{$t('message.faq')}}</a><br/>
                <!-- <router-link class="footerLinks" :to="{name : 'videoGuides'}">{{$t('message.video_guides')}}</router-link><br/> -->
                <a href="#" class="footerLinks" @click="externalLink(links.cookie_policy_link)">{{$t('message.cookie_policy')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.tos_link)">{{$t('message.tos')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.gdpr_link)">{{$t('message.gdpr')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.sla_link)">{{$t('message.sla')}}</a><br/>
                <a href="#" class="footerLinks" @click="externalLink(links.use_policy_link)">{{$t('message.use_policy')}}</a><br/>
              </div>
            </div>
          </div>


        </div>

        <div class="col-sm-12 col-md-3">
          <div class="partnersLogo loghi-nav ">
            <p class="footerLinks">powered by</p>
            <a href="https://www.lenovo.com/it/it/data-center/Lenovo-ThinkAgile/p/thinkagile"  target="_blank" class="navbar-brand">
              <img src="/img/lenovo.svg" class="img-fluid"  alt=""><br/>
              <img src="/img/logo-intel-rwd.png" class="img-fluid" alt="">
            </a>

          </div>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
  import nav from '@/_nav';
  import {
    Aside as AppAside,
    AsideToggler,
    Breadcrumb,
    Footer as TheFooter,
    Header as AppHeader,
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    SidebarToggler,

  } from '@coreui/vue';
  import DefaultAside from './DefaultAside';
  import DefaultHeaderDropdown from './DefaultHeaderDropdown';
  import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
  import DefaultHeaderDropdownCart from "./DefaultHeaderDropdownCart";
  import DefaultHeaderDropdownAccountAnonymous from "./DefaultHeaderDropdownAccountAnonymous";
  import LanguageSelector from "../components/i18n/LanguageSelector";
  import LanguageSelectorMobile from "../components/i18n/LanguageSelectorMobile";

  import {mapState, mapGetters} from "vuex";
  import I18n from "../components/i18n/I18n";
  import Alert from "../components/ui/Alert";

  export default {
    name: 'DefaultContainer',
    components: {
      AsideToggler,
      AppHeader,
      AppSidebar,
      AppAside,
      TheFooter,
      Breadcrumb,
      DefaultAside,
      DefaultHeaderDropdown,
      DefaultHeaderDropdownAccnt,
      DefaultHeaderDropdownAccountAnonymous,
      DefaultHeaderDropdownCart,
      SidebarForm,
      SidebarFooter,
      SidebarToggler,
      SidebarHeader,
      SidebarNav,
      SidebarMinimizer,
      LanguageSelector,
      LanguageSelectorMobile,
      Alert
    },
    data() {
      return {
        currentLocale: this.$i18n.locale,
        currentVps: null,
        dismissSecs: 10,
        dismissCountDown: 0,
        links : {
          control_panel  : process.env.VUE_APP_ADMIN,
          edit_profile  : process.env.VUE_APP_PROFILE,
          landing: process.env.VUE_APP_LANDING,
          cookie_policy_link: process.env.VUE_APP_COOKIE_POLICY,
          tos_link: process.env.VUE_APP_TOS,
          gdpr_link: process.env.VUE_APP_GDPR,
          sla_link: process.env.VUE_APP_SLA,
          use_policy_link: process.env.VUE_APP_USE_POLICY,
          faq: process.env.VUE_APP_FAQ
        },
      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.user.currentUser,
        currentCart: state => state.cart,
      }),
      currentRoute() {
        return this.$route.name
      },
      name() {
        return this.$route.name;
      },
      list() {
        return this.$route.matched.filter(route => route.name || route.meta.label);
      },
    },
    methods: {
      externalLink(link){
        window.location.href = link;
      },
      isCartShow(){
        return this.$route.name === 'paymentSuccess';
      },
      async doLogout(event) {
        await this.$store.dispatch('user/logout');
      }
    },
    mounted() {
      let cart =  this.$store.dispatch('cart/get');
    }
  };
</script>
<style scoped>

</style>

<style lang="scss">
@import '../assets/scss/mailchimps.scss';
</style>

<style>
  a.footerLinks:hover {
    color: #ffffff;
    text-decoration: none;
  }
  #mc_embed_signup{ clear:left; font:14px Helvetica,Arial,sans-serif; }
  /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
     We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
  #mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}
  #mergeRow-gdpr {margin-top: 20px;}
  #mergeRow-gdpr fieldset label {font-weight: normal;}
  #mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}

  .partnersLogo img {
    width: 140px;
    margin-top: 10px
  }

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px) {
    .sidebarMenuLinks {
      padding-bottom: 2px !important;
    }
    .hideMobile {
      display: none;
    }
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px) {
    .sidebarMenuLinks {
      padding-bottom: 4px !important;
    }
    .hideMobile {
      display: none;
    }

  }

</style>
