<template>
  <AppHeaderDropdown no-caret right class="customDropdownAccountAnonymous">
    <template slot="header">
      {{$t('message.contact_us')}}  <span class="fa fa-caret-down"></span>
    </template>
    <template slot="dropdown">
      <b-dropdown-item >
        <i class="fa fa-life-saver "/>
        <router-link :to="{name : 'anonymousSupport'}">{{$t('message.customer-care')}}</router-link>
      </b-dropdown-item>
      <b-dropdown-item>
        <i class="fa fa-star "/>
        <router-link :to="{name : 'anonymousFeedback'}">{{$t('message.leave_feedback')}}</router-link>
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
    import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'

    export default {
        name: 'DefaultHeaderDropdownAccountAnonymous',
        components: {
            AppHeaderDropdown
        },
        data: () => {
            return {
              links : {
                control_panel  : process.env.VUE_APP_ADMIN,
                edit_profile  : process.env.VUE_APP_PROFILE,
                landing: process.env.VUE_APP_LANDING
              },
            }
        },
        methods: {
          externalLink(link){
            window.location.href = link;
          },
        }
    }
</script>
<style type="text/css">
  .customDropdownAccountAnonymous ul.dropdown-menu {
    width: 300px !important;
  }



</style>
