<template>
  <AppHeaderDropdown right no-caret v-if="itemsCount" class="customDropdown">
    <template slot="header">
      <i class="icon-basket font-2xl"  ></i>
      <b-badge class="m-1" pill variant="info" >{{itemsCount}}</b-badge>
    </template>
    <template slot="dropdown"  v-if="vps.length">
      <div class="w-100">
        <b-dropdown-header  tag="div" class="text-center">
          <strong>{{$t('message.you_got')}} {{itemsCount}} {{$t('message.vps_in_cart')}}</strong><br/>
          <small>{{$t('message.order_number')}} {{cart.number}}</small>
        </b-dropdown-header>
        <b-dropdown-item>
          <div class="message">
            <div>
              <span class="font-weight-bold"> {{vps[0].description}}</span>
            </div>

            <div class="font-weight-bold" v-if="os[0].description">
              <small class="font-weight-bold">{{$t('message.operating_system')}}</small>
              <br/>
              <small class="text-muted">{{os[0].description}}</small>
            </div>

            <div class="font-weight-bold" v-if="cp.length">
              <small class="font-weight-bold">{{$t('message.control_panel')}}</small>
              <br/>
              <small class="text-muted" v-if="cp.length">{{cp[0].description}}</small>
            </div>

            <div class="font-weight-bold" v-if="ipv4.length && ipv4[0].quantity > 0">
              <small class="font-weight-bold">{{$t('message.further_ip')}}</small>
              <br/>
              <small class="text-muted"v-if="ipv4.length && ipv4[0].quantity > 0">{{ipv4[0].quantity}} x {{ipv4[0].description}}</small>
            </div>
          </div>
        </b-dropdown-item>

        <b-dropdown-item href="#" class="text-center">
           <span class="text-muted">

             {{cart.amount | currency('&euro;', 2, {
                spaceBetweenAmountAndSymbol: true, decimalSeparator: ',' , thousandsSeparator: '.'}) }}
            <small>{{$t('message.plus_vat')}}</small>
            {{cart.totals.month > 1 ?   $t('message.every')  + ' ' + cart.totals.month + ' ' + $t('message.months') : $t('message.every_month')}} </span>
        </b-dropdown-item>
        <b-dropdown-item href="#">
          <span class="col-3 text-center" v-if="this.$route.name !== 'productDetail'">
            <router-link :to="{name : 'productDetail',params: {productCode: vps[0].code}}">
              <i class="icon-pencil"></i> {{$t('message.edit')}}
            </router-link>
          </span>
          <span class="col-3 text-center">
            <router-link :to="{name : 'checkout'}">
              <i class="icon-credit-card"></i> {{$t('message.pay')}}
            </router-link>
          </span>
          <span class="col-3 text-center">
            <a href="" @click="deleteCart">
              <i class="icon-trash"></i> {{$t('message.delete')}}
            </a>
          </span>
        </b-dropdown-item>
      </div>
    </template>
  </AppHeaderDropdown>
</template>
<script>
  import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
  import {mapState, mapGetters} from "vuex";
  import Api from "../components/common/Api";
  import ApiResolver from "../components/common/ApiResolver";


  export default {
    name: 'DefaultHeaderDropdownMssgs',
    components: {
      AppHeaderDropdown
    },
    data: () => {
      return {}
    },
    methods: {
      deleteCart()  {
        Api.put(ApiResolver.get('CART_DELETE'), {})
          .then((response) => {
            let cart = this.$store.dispatch('cart/get');
            //console.log(cart);

            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.cart_delete_succesfully')
            });
            this.$router.push({name: 'categories'});

          })
          .catch(function (error) {
            // handle error

            //console.log(error);
          });
      }
    },
    computed: {
      ...mapState({
        cart: state => state.cart,
        itemsCount: state => state.cart.number ? 1 : null,
        vps: state => state.cart.rows.filter((item) => (item.type === 'vps')),
        os: state => state.cart.rows.filter((item) => (item.type === 'os')),
        cp: state => state.cart.rows.filter((item) => (item.type === 'cp')),
        ipv4: state => state.cart.rows.filter((item) => (item.type === 'ipv4')),
      }),
    },
  }
</script>

<style type="text/css">
  .customDropdown ul.dropdown-menu {
    width: 400px !important;
  }
</style>
