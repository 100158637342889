<template>
  <AppHeaderDropdown no-caret right class="customDropdownAccount">
    <template slot="header">
      <img
        :src="getAvatar"
        alt="avatar"
        class="img-avatar"
        id="miniAvatar"
        v-if="currentUser && !currentUser.anonymous"
      />
      <small style="font-size: 15px !important; color: #2f353a !important; min-width: 130px !important" v-if="currentUser && !currentUser.anonymous">
        {{ currentUser.displayName }}
      </small>
    </template>

    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center" ><strong>Account </strong></b-dropdown-header>
      <b-dropdown-item>
        <i class="fa fa-lock"></i>
        <router-link :to="{name : 'changePassword'}">{{$t('message.change_password')}}</router-link>
      </b-dropdown-item>
      <b-dropdown-item  v-if="currentUser && currentUser.registered === 1">
        <i class="fa fa-user"></i>
        <router-link :to="{name : 'register'}">{{$t('message.complete_registration_link')}}</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="currentUser && currentUser.customer === 1">
        <a href="#" @click="externalLink(links.edit_profile)">
          <i class="fa fa-user"></i>{{$t('message.edit_profile')}}
        </a>
      </b-dropdown-item>
      <b-dropdown-item v-if="currentUser && currentUser.customer === 1" >
        <a href="#" @click="externalLink(links.control_panel)">
          <i class="fa fa-cogs"></i>{{$t('message.control_panel')}}
        </a>
      </b-dropdown-item>
      <b-dropdown-item v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1)">
        <i class="fa fa-life-saver "></i>
        <router-link :to="{name : 'support'}">{{$t('message.customer-care')}}</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1 )">
        <i class="fa fa-star"></i>
        <router-link :to="{name : 'feedback'}">{{$t('message.leave_feedback')}}</router-link>
      </b-dropdown-item>
      <b-dropdown-item v-if="currentUser && ( currentUser.customer === 1 || currentUser.registered === 1)">
        <i class="fa fa-list"></i>
        <a href="#" @click="externalLink(links.faq)">{{$t('message.faq')}}</a><br/>

      </b-dropdown-item>
      <b-dropdown-item>
        <i class="fa fa-sign-out"></i>
        <a href="#" v-on:click.prevent="doLogout">{{$t('message.logout')}}</a>
      </b-dropdown-item>
      <b-modal
        :cancel-title="$t('message.logout_and_forgot_cart')"
        :ok-title="$t('message.logout_and_preserve_cart')"
        :title="$t('message.logout')"
        @cancel="logoutAndForgot"
        @ok="logoutAndPreserve"
        v-if="currentCart"
        v-model="logoutModal"
      >
        {{$t('message.logout_text')}}
      </b-modal>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
  import Api from "../components/common/Api";
  import ApiResolver from "../components/common/ApiResolver";

  export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
      AppHeaderDropdown
    },
    data: () => {
      return {
        links : {
          control_panel  : process.env.VUE_APP_ADMIN,
          edit_profile  : process.env.VUE_APP_PROFILE,
          landing: process.env.VUE_APP_LANDING,
          faq: process.env.VUE_APP_FAQ
        },
        currentUser: null,
        currentCart: null,
        logoutModal: false,
        miniAvatar: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }
    },
    computed: {
      getAvatar: function () {
        Api
          .get(ApiResolver.get('AVATAR'))
          .then(response => {
            if (response.data !== '') {
              this.miniAvatar = response.data;
            }
          })
          .catch(reason => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.$t(reason.title) + ' : ' + this.$t(reason.detail)
            });
          });
        return this.miniAvatar;
      }
    },
    methods: {
      externalLink(link){
        window.location.href = link;
      },
      logoutAndForgot(event) {
        this.$store.dispatch('cart/forgotCart');
        this.$store.dispatch('user/logout');

      },
      logoutAndPreserve(event) {
        this.$store.dispatch('cart/rememberCart');
        this.$store.dispatch('user/logout');
      },
      async doLogout(event) {
        await this.$store.dispatch('cart/get');
        this.currentCart = this.$store.getters['cart/getCart'];
        if (this.currentCart && this.currentCart.number) {
          this.logoutModal = true;
        } else {
          await this.$store.dispatch('user/logout');
        }
      }
    },
    async mounted() {
      this.currentUser = await this.$store.dispatch('user/current');
    }
  }
</script>

<style type="text/css">
  .customDropdownAccount ul.dropdown-menu {
    width: 420px !important;
  }
  a {
      overflow-wrap: break-word !important;
  }
</style>
