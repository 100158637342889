<template>
  <div class="hidden-md-up">

    <div class="mt-2 ml-3"  v-for="lang in languages"
          v-on:click="changeLang(lang.code)">
      <i :class="lang.icon"></i> {{lang.label}}
    </div>
  </div>
</template>

<script>

  import I18n from "./I18n";
  import {mapGetters} from "vuex";

  export default {
    name: "LanguageSelectorMobile",
    data() {
      return {
        langSelected: '',
        languages: I18n.getLangs()
      }
    },
    computed: {
      ...mapGetters({
        currentLang: "user/getCurrentLang"
      }),
      currentLangObj: function () {
        return this.languages.filter((lang) => (lang.code === this.currentLang))[0];

      }
    },
    methods: {
      changeLang(langCode) {
        I18n.setCurrentLang(langCode, true);
      },
    },
    mounted: function () {
      //console.log(this.$i18n);

    }
  }
</script>

<style scoped>

</style>
