<template>
  <div>
    <b-dropdown variant="link" size="lg">
      <template slot="button-content">
        <i :class="currentLangObj.icon"></i>
      </template>
      <b-dropdown-item
        v-for="lang in languages"
        v-on:click="changeLang(lang.code)"
      >
        <i :class="lang.icon"></i> {{lang.label}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>

  import I18n from "./I18n";
  import {mapGetters} from "vuex";

  export default {
    name: "LanguageSelector",
    data() {
      return {
        langSelected: '',
        languages: I18n.getLangs()
      }
    },
    computed: {
      ...mapGetters({
        currentLang: "user/getCurrentLang"
      }),
      currentLangObj: function () {
        return this.languages.filter((lang) => (lang.code === this.currentLang))[0];

      }
    },
    methods: {
      changeLang(langCode) {
        I18n.setCurrentLang(langCode, true);
      },
    },
    mounted: function () {
      //console.log(this.$i18n);

    }
  }
</script>

<style scoped>

</style>
